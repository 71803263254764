import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image'
import { colors, space } from '../../styles/theme';
import mediaQueries from "../../styles/mediaQueries";

export const Wrapper = styled(BackgroundImage)({
  position: 'relative',
  marginTop: space.base,
  padding: `${space.xxlarge} ${space.base}`,
  minHeight: '18rem',

  [mediaQueries.small]: {
    padding: `7.5rem ${space.base}`,
  },
});

export const Label = styled('h3')({
  color: colors.white,
  textTransform: 'uppercase',
  margin: 0,
});

export const Title = styled('h1')({
  color: colors.white,
  marginTop: 0,
  marginBottom: space.base,
});

export const Content = styled('div')({
  position: 'relative',
  zIndex: 1,

  [mediaQueries.small]: {
    textAlign: 'center',
  },
});

export const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: colors.secondary,
  opacity: 0.4,
});
