import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '.';
import Hero from '../components/Hero';
import HtmlRenderer from '../components/HtmlRenderer';
import Section from '../components/Section';
import SEO from '../components/SEO';


const ProjectPage = ({ data }) => {
  const {
    content,
    featuredImage,
    title,
    project: { description }
  } = data.wp.project;

  return (
    <Layout>
      <SEO title={title} description={description}/>
      <Hero img={featuredImage.node} title={title}/>
      <Section justifyContent="center" width={[12, 12, 8, 8]}>
        <HtmlRenderer html={content}/>
      </Section>
    </Layout>
  );
};

export default ProjectPage;

ProjectPage.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string
      }),
    }),
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      }),
    }),
    name: PropTypes.string,
    slug: PropTypes.string,
    sponsorRole: PropTypes.string,
    twitter: PropTypes.string,
    whitePages: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    }),
    website: PropTypes.string,
    youtube: PropTypes.string
  }),
};

ProjectPage.defaultProps = {
  data: {},
};

export const projectQuery = graphql`
    query GET_PROJECT($id: ID!) {
        wp {
            project(id: $id) {
                content
                title
                uri
                featuredImage {
                    node {
                        ...Image
                    }
                }
                project {
                    description
                    website
                }
            }
        }
    }
`;
