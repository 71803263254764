import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Column } from '../Layout';
import { Content, Label, Overlay, Title, Wrapper } from './Hero.style';

const Hero = ({ img, title }) => {
  return (
    <Container>
      <Grid>
        <Column width={12}>
          <Wrapper
            fluid={img.imageFile.childImageSharp.fluid}>
            <Overlay />
            <Content>
              <Title>{title}</Title>
              <Label>Project</Label>
            </Content>
          </Wrapper>
        </Column>
      </Grid>
    </Container>

  )
};

Hero.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
};

Hero.defaultProps = {
  image: null,
  title: '',
};

export default Hero;